import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Export_CSV} from '../../components/Export_CSV'
import {Export_Excel} from '../../components/Export_Excel'
import {getCurrentDateInput} from '../../components/GetCurrentDateInput'
import FormatNumber from '../../components/FormatNumber'
import TableTemplate from '../../components/Table/TableTemplate'
import {TableTemplateCSS} from '../../components/Table/TableCSS'
import IndeterminateCheckbox from '../../components/Table/IndeterminateCheckbox'
import {ColumnDef, ColumnPinningState} from '@tanstack/react-table'
import FormatDate from '../../components/FormatDate'
import {Wallet_Transaction_Summary_Filter} from './Wallet-Transaction-Summary-Filter'
import {Wallet_Transaction_Summary_ExportPDF} from './Wallet-Transaction-Summary-ExportPDF'

const Wallet_Transaction_Summary_List: React.FC = () => {
  //Table Related
  const [rowSelection, setRowSelection] = React.useState({})
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
    left: ['select'],
    right: ['action'],
  })
  const [columnVisibility, setColumnVisibility] = React.useState({})
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 200, //default page size
  })
  const [totalCount, setTotalCount] = useState(0)
  useEffect(() => {
    const selectedIndices = Object.keys(rowSelection).map((key) => parseInt(key, 10))
    const filteredSelectedRowData: any = formData
      .filter((_: any, index: number) => selectedIndices.includes(index))
      .map((row: any) => row)

    setSelectedArray(filteredSelectedRowData)
  }, [rowSelection])
  //Table Related

  //States
  const [formData, setFormData] = useState([])
  const [selectedArray, setSelectedArray] = useState([])
  const [showMetrics, setShowMetrics] = useState(false)
  const [downloadAction, setDownloadAction] = useState(false)

  useEffect(() => {
    const selectedIndices = Object.keys(rowSelection).map((key) => parseInt(key, 10))
    const filteredSelectedRowData: any = formData
      .filter((_: any, index: number) => selectedIndices.includes(index))
      .map((row: any) => ({id: row.id}))

    setSelectedArray(filteredSelectedRowData)
  }, [rowSelection])

  const keyMap = {
    walletId: 'Wallet ID',
    walletAccName: 'Wallet Account Name',
    walletAccNo: 'Wallet Account No',
    walletCd: 'Wallet Code',
    type: 'Type',
    walletBalance: 'Wallet Balance',
    depositCount: 'Deposit Count',
    depositAmount: 'Deposit Amount',
    depositComm: 'Deposit Commission',
    withdrawalCount: 'Withdrawal Count',
    withdrawalAmount: 'Withdrawal Amount',
    withdrawalComm: 'Withdrawal Commission',
    b2bReceivedCount: 'B2B Received Count',
    b2bReceivedAmount: 'B2B Received Amount',
    b2bSendCount: 'B2B Send Count',
    b2bSendAmount: 'B2B Send Amount',
    settlementDay: 'Settlement Day',
    createdDate: 'Created Date',
  }

  const columns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'select',
        accessorKey: 'select',
        enableColumnFilter: false,
        size: 50,
        header: ({table}) => (
          <div
            className='px-1'
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          </div>
        ),
        cell: ({row}) => (
          <div
            className='px-1'
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: 'walletId',
        header: 'Wallet ID',
        accessorKey: 'walletId',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        id: 'walletAccName',
        header: 'Wallet Account Name',
        accessorKey: 'walletAccName',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Wallet Account No',
        accessorKey: 'walletAccNo',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Wallet Code',
        accessorKey: 'walletCd',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Type',
        accessorKey: 'type',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Wallet Balance',
        accessorKey: 'walletBalance',
        cell: (info: any) => FormatNumber(info.getValue()),
        enableColumnFilter: false,
      },
      {
        header: 'Deposit Count',
        accessorKey: 'depositCount',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Deposit Amount',
        accessorKey: 'depositAmount',
        cell: (info: any) => FormatNumber(info.getValue()),
        enableColumnFilter: false,
      },
      {
        header: 'Deposit Commission',
        accessorKey: 'depositComm',
        cell: (info: any) => FormatNumber(info.getValue()),
        enableColumnFilter: false,
      },
      {
        header: 'Withdrawal Count',
        accessorKey: 'withdrawalCount',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'Withdrawal Amount',
        accessorKey: 'withdrawalAmount',
        cell: (info: any) => FormatNumber(info.getValue()),
        enableColumnFilter: false,
      },
      {
        header: 'Withdrawal Commission',
        accessorKey: 'withdrawalComm',
        cell: (info: any) => FormatNumber(info.getValue()),
        enableColumnFilter: false,
      },
      {
        header: 'B2B Received Count',
        accessorKey: 'b2bReceivedCount',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'B2B Received Amount',
        accessorKey: 'b2bReceivedAmount',
        cell: (info: any) => FormatNumber(info.getValue()),
        enableColumnFilter: false,
      },
      {
        header: 'B2B Send Count',
        accessorKey: 'b2bSendCount',
        cell: (info: any) => info.getValue(),
        enableColumnFilter: false,
      },
      {
        header: 'B2B Send Amount',
        accessorKey: 'b2bSendAmount',
        cell: (info: any) => FormatNumber(info.getValue()),
        enableColumnFilter: false,
      },
      {
        header: 'Settlement Date',
        accessorKey: 'settlementDay',
        cell: (info: any) => FormatDate(info.getValue()),
        enableColumnFilter: false,
        size: 200,
      },
      {
        header: 'Created Date',
        accessorKey: 'createdDate',
        cell: (info: any) => FormatDate(info.getValue()),
        enableColumnFilter: false,
        size: 200,
      },
    ],
    []
  )

  return (
    <div>
      <div className='d-flex justify-content-between mb-5'>
        {/* <FilterButton /> */}
        <div className='m-0 d-flex'>
          <div
            className='position-relative me-5'
            style={{
              width: '180px',
            }}
          >
            <div
              className='btn btn-sm fw-bold btn-color-white-700 btn-info w-100'
              onClick={() => {
                setDownloadAction(!downloadAction)
              }}
            >
              Download Record As:
            </div>{' '}
            {downloadAction && (
              <div
                className='action-dropdown d-flex-row position-absolute mt-1 w-100 aligns-item-center justify-contenet-center'
                style={{
                  zIndex: '999',
                }}
              >
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Export_CSV(
                      formData,
                      keyMap,
                      getCurrentDateInput(),
                      getCurrentDateInput(),
                      'Wallet_List'
                    )
                    setDownloadAction(!downloadAction)
                  }}
                >
                  CSV
                </button>
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Export_Excel(
                      formData,
                      keyMap,
                      getCurrentDateInput(),
                      getCurrentDateInput(),
                      'Wallet_List'
                    )
                    setDownloadAction(!downloadAction)
                  }}
                >
                  Excel WorkBook
                </button>{' '}
                <button
                  className='btn btn-sm fw-bold btn-color-white-700 w-100 btn-active-color-warning'
                  disabled={formData.length > 0 ? false : true}
                  onClick={() => {
                    Wallet_Transaction_Summary_ExportPDF(
                      formData,
                      getCurrentDateInput(),
                      getCurrentDateInput()
                    )
                    setDownloadAction(!downloadAction)
                  }}
                >
                  PDF
                </button>{' '}
              </div>
            )}
          </div>
        </div>

        <div className='m-0 d-flex '>
          <button
            type='button'
            className='btn btn-sm btn-flex fw-bold rotate'
            onClick={(e) => {
              setShowMetrics(!showMetrics)
              e.currentTarget.classList.toggle('active')
            }}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr004.svg'
              className='svg-icon-6 svg-icon-muted me-1 rotate-180'
            />{' '}
            Select Metrics
          </button>
        </div>
      </div>{' '}
      <Wallet_Transaction_Summary_Filter
        setFormData={setFormData}
        setSelectedArray={setSelectedArray}
        setRowSelection={setRowSelection}
      />
      <TableTemplateCSS>
        <TableTemplate
          data={formData}
          columns={columns}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnPinning={columnPinning}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          showMetrics={showMetrics}
          manualPagination={false}
          pagination={pagination}
          setPagination={setPagination}
          totalCount={totalCount}
        />
      </TableTemplateCSS>
    </div>
  )
}

export default Wallet_Transaction_Summary_List
