import {useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import axios from 'axios'
import {useAuth} from '../../auth'
import {useWalletTransactionSummaryStore} from './Wallet-Transaction-Summary-Store'
import {ErrorHandling} from '../../components/ErrorHandling'
import {sortByName} from '../../components/SortByName'
import {Overlay} from '../../components/Overlay'
import {customStyles} from '../../components/CustomStyles'
import Select from 'react-select'
import {getCurrentDateInput} from '../../components/GetCurrentDateInput'

export function Wallet_Transaction_Summary_Filter(props: any) {
  const {logout} = useAuth()
  const SISPAY_API = String(process.env.REACT_APP_SISPAY_API)
  const session: any = localStorage.getItem('kt-auth-react-v')?.replace(/"/g, '')

  //Props
  let setFormData = props.setFormData
  let setSelectedArray = props.setSelectedArray
  let setRowSelection = props.setRowSelection

  //States
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)

  //Refs
  const selectedMerchantCd = useRef<any>(null)

  //Zustand Store
  const filterOptions = useWalletTransactionSummaryStore((state: any) => state.filterOptions)
  const getData = useWalletTransactionSummaryStore((state: any) => state.getData)
  const setFilterOptions = useWalletTransactionSummaryStore((state: any) => state.setFilterOptions)
  const setGetData = useWalletTransactionSummaryStore((state: any) => state.setGetData)

  const initialValues = {
    search: filterOptions.search ? String(filterOptions.search) : '',
    type: filterOptions.type,
    timezone: filterOptions.timezone ? String(filterOptions.timezone) : 'GMT+6',
    dateTimeFrom: filterOptions.dateTimeFrom
      ? String(filterOptions.dateTimeFrom)
      : getCurrentDateInput(),
    dateTimeTo: filterOptions.dateTimeTo ? String(filterOptions.dateTimeTo) : getCurrentDateInput(),
  }

  const filterSchema = Yup.object().shape({
    search: Yup.string(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setDisabled(true)
      setLoading(true)
      let search = values.search.trim()
      let type = values.type
      let timezone = values.timezone
      let dateTimeFrom = values.dateTimeFrom
      let dateTimeTo = values.dateTimeTo

      setFilterOptions(values)

      axios
        .post(
          `${SISPAY_API}/wallet/transactionsummary/list`,
          {
            request: {
              session: session,
              fromDate: dateTimeFrom,
              toDate: dateTimeTo,
              timezone: timezone,
              ...(search && {walletId: search}),
              ...(type && {type: type}),
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          if (response.data.code == 200) {
            let result = response.data.result.waleltTransactions

            setFormData(result)
            setRowSelection({})
            setDisabled(false)
            setLoading(false)
          } else {
            ErrorHandling(response, logout)
          }
        })
        .catch(function (error) {
          // alert(error)
        })
    },
  })

  function handleRest() {
    setSelectedArray([])
    setRowSelection({})
    formik.resetForm({
      values: {
        search: '',
        type: '',
        timezone: '',
        dateTimeFrom: getCurrentDateInput(),
        dateTimeTo: getCurrentDateInput(),
      },
    })
  }

  return (
    <div className='card card-custom card-flush mb-5'>
      <div className='menu d-flex flex-column'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-2 py-1'>
          <div className='mb-10'>
            <form onSubmit={formik.handleSubmit} className='m-5'>
              {' '}
              <div className='fv-row mb-5'>
                {' '}
                <div className='fv-row mb-5'>
                  <div className='row'>
                    <div className='col'>
                      {' '}
                      <div className='d-flex'>
                        <label className='form-label fw-bolder text-dark fs-6'>Search</label>
                        {Overlay('SEARCH Wallet ID')}
                      </div>
                      <input
                        placeholder='Enter your search'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('search')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.search && formik.errors.search,
                          },
                          {
                            'is-valid': formik.touched.search && !formik.errors.search,
                          }
                        )}
                      />
                      {formik.touched.search && formik.errors.search && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.search}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='col'>
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Type</label>
                        <select
                          {...formik.getFieldProps('type')}
                          className={clsx('form-control bg-transparent')}
                          data-placeholder=''
                        >
                          <option value=''>ALL</option>
                          <option value='OPENING'>OPENING</option>
                          <option value='CLOSING'>CLOSING</option>
                        </select>
                      </div>{' '}
                    </div>
                    <div className='col'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>Timezone</label>
                        <select
                          {...formik.getFieldProps('timezone')}
                          className={clsx('form-control bg-transparent')}
                          data-placeholder=''
                        >
                          <option value='GMT+6'>GMT+6</option>
                          <option value='GMT+7'>GMT+7</option>
                          <option value='GMT+8'>GMT+8</option>
                        </select>{' '}
                      </div>{' '}
                    </div>
                    <div className='col'>
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>From</label>
                        <input
                          placeholder='Enter your start time'
                          type='date'
                          autoComplete='off'
                          {...formik.getFieldProps('dateTimeFrom')}
                          className={clsx(
                            'form-control bg-transparent',
                            {
                              'is-invalid':
                                formik.touched.dateTimeFrom && formik.errors.dateTimeFrom,
                            },
                            {
                              'is-valid':
                                formik.touched.dateTimeFrom && !formik.errors.dateTimeFrom,
                            }
                          )}
                        />
                        {formik.touched.dateTimeFrom && formik.errors.dateTimeFrom && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.dateTimeFrom}</span>
                            </div>
                          </div>
                        )}
                      </div>{' '}
                    </div>
                    <div className='col'>
                      <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>To</label>
                        <input
                          placeholder='Enter your end time'
                          type='date'
                          autoComplete='off'
                          {...formik.getFieldProps('dateTimeTo')}
                          className={clsx(
                            'form-control bg-transparent',
                            {
                              'is-invalid': formik.touched.dateTimeTo && formik.errors.dateTimeTo,
                            },
                            {
                              'is-valid': formik.touched.dateTimeTo && !formik.errors.dateTimeTo,
                            }
                          )}
                        />
                        {formik.touched.dateTimeTo && formik.errors.dateTimeTo && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.dateTimeTo}</span>
                            </div>
                          </div>
                        )}
                      </div>{' '}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'></div>
                <div className='col'></div>
                <div className='col'>
                  <div className='text-center'>
                    <button
                      className='btn btn-lg btn-light w-100 mb-5'
                      disabled={disabled}
                      onClick={handleRest}
                    >
                      {!loading && <span className='indicator-label'>Reset</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
                <div className='col'>
                  {/* begin::Form group */}
                  <div className='text-center'>
                    <button
                      type='submit'
                      id='kt_sign_up_submit'
                      className='btn btn-lg btn-primary w-100 mb-5'
                      disabled={formik.isSubmitting || !formik.isValid || disabled}
                      // onClick={() => {
                      //   document.getElementById('filterBtn')!.click()
                      // }}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* end::Form group */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>{' '}
    </div>
  )
}
