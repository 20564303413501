import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

export function Wallet_Transaction_Summary_ExportPDF(
  formData: any,
  startDate: string,
  endDate: string
) {
  // Register the fonts with pdfMake
  pdfMake.vfs = pdfFonts.pdfMake.vfs

  const documentDefinition = {
    pageOrientation: 'landscape' as 'landscape', // Cast the pageOrientation value explicitly
    pageSize: 'A0' as 'A0',
    content: [
      {text: `Exported Data ${startDate} - ${endDate}`, style: 'header'},
      '\n', // Add a line break
      {
        table: {
          //widths: ['*', '*', '*'], // Adjust column widths as needed
          body: [
            [
              'Wallet ID',
              'Wallet Account Name',
              'Wallet Account No',
              'Wallet Code',
              'Type',
              'Wallet Balance',
              'Deposit Count',
              'Deposit Amount',
              'Deposit Commission',
              'Withdrawal Count',
              'Withdrawal Amount',
              'Withdrawal Commission',
              'B2B Received Count',
              'B2B Received Amount',
              'B2B Send Count',
              'B2B Send Amount',
              'Settlement Day',
              'Created Date',
            ], // Add headers as a separate row
            ...formData.map((item: any) => [
              item.walletId,
              item.walletAccName,
              item.walletAccNo,
              item.walletCd,
              item.type,
              item.walletBalance,
              item.depositCount,
              item.depositAmount,
              item.depositComm,
              item.withdrawalCount,
              item.withdrawalAmount,
              item.withdrawalComm,
              item.b2bReceivedCount,
              item.b2bReceivedAmount,
              item.b2bSendCount,
              item.b2bSendAmount,
              item.settlementDay,
              item.createdDate,
            ]),
          ],
        },
        layout: 'lightHorizontalLines', // Add a layout to handle long tables
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
      },
    },
  }

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition)
  pdfDocGenerator.download(`Exported Data ${startDate} - ${endDate}.pdf`)
}
