import {create} from 'zustand'
import {getCurrentDateInput} from '../../components/GetCurrentDateInput'

export const useWalletTransactionSummaryStore = create((set) => ({
  //Data
  getData: false,
  filterOptions: {
    search: '',
    type: '',
    timezone: '',
    startDate: getCurrentDateInput(),
    endDate: getCurrentDateInput(),
  },

  //Functions
  setGetData: (value: boolean) => set({getData: value}),
  setFilterOptions: (value: any) => set({filterOptions: value}),
}))
