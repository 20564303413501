import {CSSProperties} from 'react'

import {
  Column,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  Table,
  useReactTable,
} from '@tanstack/react-table'

export default function TableTemplate(props: any) {
  let data: any = props.data
  let columns: any = props.columns
  let rowSelection: any = props.rowSelection
  let setRowSelection: any = props.setRowSelection
  let columnPinning: any = props.columnPinning
  let columnVisibility: any = props.columnVisibility
  let setColumnVisibility: any = props.setColumnVisibility
  let showMetrics: any = props.showMetrics
  let manualPagination: any = props.manualPagination
  let pagination: any = props.pagination
  let setPagination: any = props.setPagination
  let totalCount: any = props.totalCount

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      columnPinning,
      columnVisibility,
      pagination,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: manualPagination, // This is required for server-side pagination
    onPaginationChange: setPagination, //update the pagination state when internal APIs mutate the pagination state
    rowCount: manualPagination ? totalCount : data.length,
    // debugTable: true,
    defaultColumn: {
      size: 150, //starting column size
      minSize: 50, //enforced during column resizing
      maxSize: 500, //enforced during column resizing
    },
    initialState: {
      pagination: {
        // pageIndex: 2, //custom initial page index
        pageSize: 200, //custom default page size
      },
    },
  })

  const getCommonPinningStyles = (column: Column<any>): CSSProperties => {
    const isPinned = column.getIsPinned()
    const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left')
    const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right')

    return {
      boxShadow: isLastLeftPinnedColumn
        ? '-4px 0 4px -4px gray inset'
        : isFirstRightPinnedColumn
        ? '4px 0 4px -4px gray inset'
        : undefined,
      left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
      right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
      position: isPinned ? 'sticky' : 'relative',
      width: column.getSize(),
      zIndex: isPinned ? 1 : 0,
      backgroundColor: isPinned ? 'var(--kt-card-bg)' : undefined,
    }
  }

  return (
    <div className=''>
      <div className='h-2' />
      <div
        className='card card-custom p-5 mb-5'
        style={{
          display: showMetrics ? 'grid' : 'none',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '10px',
        }}
      >
        <div className='px-1 border-b border-black'>
          <div className='form-check form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              style={{cursor: 'pointer'}}
              {...{
                type: 'checkbox',
                checked: table.getIsAllColumnsVisible(),
                onChange: table.getToggleAllColumnsVisibilityHandler(),
              }}
            />
            <label className='form-check-label'>Toggle All</label>
          </div>
        </div>
        {table
          .getAllLeafColumns()
          .filter((column) => column.id !== 'select' && column.id !== 'action')
          .map((column: any) => {
            return (
              <div key={column.id} className='px-1'>
                <div className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    style={{cursor: 'pointer'}}
                    {...{
                      type: 'checkbox',
                      checked: column.getIsVisible(),
                      onChange: column.getToggleVisibilityHandler(),
                    }}
                  />
                  <label className='form-check-label'>{column.columnDef.header}</label>
                </div>
              </div>
            )
          })}
      </div>
      <div className='card card-custom p-5' style={{maxHeight: '80vh'}}>
        {data.length > 0 ? (
          <div className='table-responsive'>
            <table style={{width: table.getCenterTotalSize()}} className='table'>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className='fw-bold fs-6 text-gray-800 border-bottom border-white'
                  >
                    {headerGroup.headers.map((header) => {
                      const {column} = header
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          className='p-3'
                          style={{width: header.getSize(), ...getCommonPinningStyles(column)}}
                        >
                          {header.isPlaceholder ? null : (
                            <>
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {header.column.getCanFilter() ? (
                                <div>
                                  <Filter column={header.column} table={table} />
                                </div>
                              ) : null}
                            </>
                          )}
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        const {column} = cell
                        return (
                          <td
                            key={cell.id}
                            className='p-3'
                            style={{
                              width: cell.column.getSize(),
                              ...getCommonPinningStyles(column),
                            }}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className='text-center'>No Data Found</div>
        )}
      </div>
      <div className='h-2' />
      <div className='d-flex justify-content-center align-items-center w-100 gap-1 mt-3'>
        <div className='me-auto d-flex flex-row justify-content-center align-items-center'>
          <div className='me-1'>Total: </div>
          <strong>{manualPagination ? totalCount : data.length}</strong>
        </div>
        <div className='d-flex justify-content-center align-items-center w-100 gap-1 mt-3'>
          <button
            className='btn btn-secondary border rounded p-1 me-2 '
            style={{width: '40px'}}
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            className='btn btn-secondary border rounded p-1 me-2'
            style={{width: '40px'}}
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            className='btn btn-secondary border rounded p-1 me-2'
            style={{width: '40px'}}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className='btn btn-secondary border rounded p-1 me-2'
            style={{width: '40px'}}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
          <span className='d-flex align-items-center gap-1'>
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </strong>
          </span>
          <span className='d-flex align-items-center gap-1'>
            | Go to page:
            <input
              type='number'
              min='1'
              max={table.getPageCount()}
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                table.setPageIndex(page)
              }}
              className='border p-1 rounded w-16 me-2'
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value))
            }}
            className='btn btn-secondary border rounded p-1'
          >
            {[200, 250, 300].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      <div>
        {Object.keys(rowSelection).length} of {table.getPreFilteredRowModel().rows.length} Total
        Rows Selected
      </div>
      <hr />
      <br />
    </div>
  )
}

function Filter({column, table}: {column: Column<any, any>; table: Table<any>}) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)

  return typeof firstValue === 'number' ? (
    <div className='flex space-x-2'>
      <input
        type='number'
        value={((column.getFilterValue() as any)?.[0] ?? '') as string}
        onChange={(e) => column.setFilterValue((old: any) => [e.target.value, old?.[1]])}
        placeholder={`Min`}
        className='w-24 border shadow rounded'
      />
      <input
        type='number'
        value={((column.getFilterValue() as any)?.[1] ?? '') as string}
        onChange={(e) => column.setFilterValue((old: any) => [old?.[0], e.target.value])}
        placeholder={`Max`}
        className='w-24 border shadow rounded'
      />
    </div>
  ) : (
    <input
      type='text'
      value={(column.getFilterValue() ?? '') as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Search...`}
      className='w-36 border shadow rounded'
    />
  )
}
